import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MaterialModule } from '../material/material.module';

import { SettingsService } from './settings.service';
import { PreviewService } from './preview.service';
import { GalleryImageComponent } from './gallery-image/gallery-image.component';
import { LayoutComponent } from './layout/layout.component';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { SearchComponent } from './search/search.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PublishComponent } from './publish/publish.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TaggingComponent } from './tagging/tagging.component';
import { TagComponent } from './tag/tag.component';
import { AdminComponent } from '../admin/admin.component';

@NgModule({

    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        MaterialModule
    ],

    declarations: [
        GalleryComponent,
        GalleryImageComponent,
        GalleryListComponent,
        ImageViewerComponent,
        LayoutComponent,
        SearchComponent,
        SearchResultsComponent,
        TagListComponent,
        TaggingComponent,
        TagComponent,
        PublishComponent,
        AdminComponent
    ],

    //providers should not be registered here as lazy loaded modules will result in two copies of the service...
    //https://angular-2-training-book.rangle.io/handout/modules/feature-modules.html#services-and-lazy-loaded-modules
    providers: [
    ],

    exports: [
    ]
})
export class GalleriesModule {

    //instead we return the module + providers here and call this method in the app module
    //TODO: do all providers need adding here or private ones above?
    static forRoot(): ModuleWithProviders<GalleriesModule> {

        return {
            ngModule: GalleriesModule,

            providers: [
                SettingsService,
                PreviewService
            ]
        };
    }
}
