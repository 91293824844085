<div>
    <input placeholder="search"
           [value]="currentSearch"
           (keyup)="cleanSearchText($event.target)"
           (keyup.enter)="search(searchTerms.value, $event)"
           #searchTerms />
    <span class="material-icons"
          (click)="search(searchTerms.value, $event)">search</span>
    <span *ngIf="showControls && showClear"
          class="material-icons"
          (click)="clearSearch($event)">clear</span>
    <!--<span *ngIf="showControls"
          class="material-icons"
          (click)="toggleFilters($event)">filter_alt</span>-->
</div>
