import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './material/material.module';

import { AuthModule, AuthHttpInterceptor, HttpMethod, AuthService } from '@auth0/auth0-angular';

import { GalleriesModule } from './galleries/galleries.module';

import { PicsAuthService } from './pics-auth.service';
import { LoggerService } from './logger.service';

import { mvpRoutes } from './routes';
import { NavComponent } from './nav/nav.component';

import { environment } from '@env/environment';

@NgModule({

    declarations: [
        AppComponent,
        NavComponent
    ],

    imports: [
        BrowserModule,
        AppRoutingModule,

        GalleriesModule.forRoot(),
        RouterModule.forRoot(mvpRoutes), //{ enableTracing: true }),

        MaterialModule,
        BrowserAnimationsModule,

        AuthModule.forRoot({
            domain: environment.AuthDomain,
            clientId: environment.AuthClientId,
            useRefreshTokens: true,
            //cacheLocation: "localstorage", //default is in memory, so local storage is optional
            //useRefreshTokensFallback: true, //uses an iframe if a refresh token cannot be returned
            authorizationParams: {
                audience: environment.AuthApiIdentifier,
                redirect_uri: window.location.origin,
            },
            
            //cannot request scopes here as this stops the SDK finding the cache of the tokens (inc refresh tokens)
            //this doesn't actually matter as permissions are still returned due to RBAC being configured and users having permissions defined in Auth0
            //see here: https://auth0.com/docs/get-started/apis/enable-role-based-access-control-for-apis
            //requesting scopes here will cause this issue regardless of whether cacheLocation is set or not
            httpInterceptor: {
                allowedList: [
                    {
                        uri: `${environment.apiRoot}/*`,
                        httpMethod: HttpMethod.Get,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.readScope
                            }
                        }
                    },

                    {
                        uri: `${environment.apiRoot}/*`,
                        httpMethod: HttpMethod.Delete,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.writeScope
                            }
                        }
                    },

                    {
                        uri: `${environment.apiRoot}/*`,
                        httpMethod: HttpMethod.Patch,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.writeScope
                            }
                        }
                    },

                    {
                        uri: `${environment.apiRoot}/*`,
                        httpMethod: HttpMethod.Post,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.writeScope
                            }
                        }
                    },

                    {
                        uri: `${environment.apiRoot}/*`,
                        httpMethod: HttpMethod.Put,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.writeScope
                            }
                        }
                    },

                    {
                        uri: `${environment.searchRoot}/*`,
                        httpMethod: HttpMethod.Get,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.searchScope
                            }
                        }
                    },

                    {
                        uri: `${environment.searchRoot}/*`,
                        httpMethod: HttpMethod.Post,
                        tokenOptions: {
                            authorizationParams: {
                                //scope: environment.writeScope
                            }
                        }
                    }
                ]
            }
        })
    ],

    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [ Router ],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        AuthService,
        PicsAuthService,
        LoggerService
    ],

    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(
        trace: Sentry.TraceService) {
    }
}
