import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from "rxjs/operators";

import { IImageDto } from './model/IImageDto';
import { LoggerService } from 'app/logger.service';
import { environment } from '@env/environment';

@Injectable()
export class PreviewService {

    private urlLookup: { [key: string]: Observable<string> } = {};

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService) {
    }

    public loadThumbnailSource(image: IImageDto, showThumbnail: boolean): Observable<string> {

        this.logger.debug("preview.service", "Load thumbnail source");
        const cacheKey = this.getCacheKey(image, showThumbnail);

        //return the observable (complete or otherwise) if we're already aware of this image id
        if (this.urlLookup[cacheKey]) {

            this.logger.debug("preview.service", `Returning existing observable for id ${image.id}`);
            return this.urlLookup[cacheKey];
        }

        //haven't dealt with this image so load it now and share its result with subsequent subscribers
        let params = showThumbnail ? new HttpParams().set("tn", "1") : {};
        this.urlLookup[cacheKey] = this.httpClient
            .get(this.createImageUrl(image), { responseType: "blob", params: params })
            .pipe(
                map(blob => {

                    this.logger.debug("preview.service", `Loading image for id '${image.id}'`);
                    return URL.createObjectURL(blob);
                }),
                shareReplay());

        //return the observable
        this.logger.debug("preview.service", "Returning observable");
        return this.urlLookup[cacheKey];
    }

    private createImageUrl(image: IImageDto): string {

        this.logger.debug("preview.service", `Create image URL for ${image.url} on ${environment.apiRoot}`);
        if (image.url?.substring(0, 1) === "/")
            return `${environment.apiRoot}${image.url}`;

        return `${environment.apiRoot}/${image.url}`;
    }

    public unloadUnusedSources(requiredImages: IImageDto[]): void {

        this.logger.debug("preview.service", "Unloading unused sources");

        const requiredIds = requiredImages.map(image => image.id);
        this.logger.debug("preview.service", "Unloading ids");

        //loop through all keys in our lookup
        for (let key in this.urlLookup) {

            //clean up the image sources if we no longer require this image
            if (requiredIds.findIndex(id => key.startsWith(`${id}_`)) < 0)
                this.unloadSource(key);
        }
    }

    public unloadSources(image: IImageDto): void {

        this.logger.debug("preview.service", `Unloading sources for '${image.id}'`);

        this.unloadSource(this.getCacheKey(image, true));
        this.unloadSource(this.getCacheKey(image, false));
    }

    private unloadSource(cacheKey: string): void {

        //check if we have a reference to this image
        if (this.urlLookup[cacheKey]) {

            //subscribe to get the object url and revoke it
            this.urlLookup[cacheKey]
                .subscribe(url => {

                    this.logger.debug("preview.service", `Found image for '${cacheKey}', revoking`);
                    URL.revokeObjectURL(url);
                })
                .unsubscribe();

            //remove the lookup for this id
            delete this.urlLookup[cacheKey];
        }
    }

    private getCacheKey(image: IImageDto, thumbnail: boolean): string {

        return `${image.url}_${thumbnail ? 1 : 0}`;
    }
}
