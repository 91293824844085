import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject } from 'rxjs';

import { SubscribingComponent } from 'app/subscribing/subscribing.component';
import { GalleryService } from '../gallery.service';
import { Thumbnail } from '../model/thumbnail';
import { LoggerService } from '../../logger.service';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'mvp-gallery-list',
    templateUrl: './gallery-list.component.html',
    styleUrls: ['./gallery-list.component.scss']
})
export class GalleryListComponent extends SubscribingComponent implements OnInit {

    public galleryPreviews$: BehaviorSubject<Array<Thumbnail>>;

    public pageSizeOptions: Array<number> = [];
    public pageSize: number = 0;
    public pageIndex: number = 0;

    public galleryCount: number = 0;

    constructor(
        private galleryService: GalleryService,
        private router: Router,
        private locationService: SettingsService,
        logger: LoggerService) {

        super(logger);

        this.galleryPreviews$ = new BehaviorSubject<Array<Thumbnail>>([]);
    }

    ngOnInit() {

        this.logger.debug("gallery-list", "Init");

        this.pageSizeOptions = this.locationService.pageSizeOptions;
        this.pageSize = this.locationService.lastPageSize;
        this.pageIndex = this.locationService.lastPage - 1;

        this.paged(<PageEvent>{ pageIndex: this.locationService.lastPage - 1, pageSize: this.locationService.lastPageSize })
    }

    public paged(pageEvent: PageEvent) {

        this.logger.debug("gallery-list", "Paged");

        this.locationService.lastPage = pageEvent.pageIndex + 1;
        this.locationService.lastPageSize = pageEvent.pageSize;

        this.subscribe(
            this.galleryService.getGalleries(this.locationService.lastPage, this.locationService.lastPageSize),
            page => {

                this.logger.debug("gallery-list", `Got ${page.pageSize} galleries (${page.totalItems} total)`);
                this.galleryCount = page.totalItems;
                this.galleryPreviews$
                    .next(page.currentPage
                        .map(g => new Thumbnail(g.previewImage, g.identifier, g.name)));
            });
    }

    gallerySelected(thumbnail: Thumbnail) {

        this.logger.debug("gallery-list", "gallerySelected");
        this.router.navigate(["/galleries", thumbnail.parentIdentifier]);
    }
}
