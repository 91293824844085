export class AvailableGallery {

    public isPublishing: boolean = false;
    public url: string = "";

    public get isPublished() {

        return !this.isPublishing && (this.url ?? "") !== "";
    }
    
    constructor(
        public location: string) {
    }
}
