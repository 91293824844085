<div class="publish-container">

    <div *ngFor="let gallery of galleries$ | async">
        <mat-card>

            <mat-card-content>
                {{gallery.location}}
                <button *ngIf="!gallery.isPublished && !gallery.isPublishing"
                        mat-icon-button (click)="publishGallery(gallery)">
                    <mat-icon>publish</mat-icon>
                </button>
                <img *ngIf="gallery.isPublishing" src="../../../assets/aperture.gif" />
                <mat-icon *ngIf="gallery.isPublished">published_with_changes</mat-icon>
            </mat-card-content>

        </mat-card>
    </div>

    <p *ngIf="(noneAvailable | async) && !(availableRequested | async)">
        refresh available to see galleries...
    </p>
    <p *ngIf="availableRequested | async">
        requesting available galleries...
    </p>

</div>
