export class Guard {

    static limitInteger(value: any, min: number, max: number, options?: { throwIfOutOfRange: boolean }): number {

        if (typeof(value) !== "number")
            value = Number.parseInt(value);

        if (!Number.isInteger(value) || value < min)
            return Guard.returnOrThrow<number>(min, options?.throwIfOutOfRange);

        if (value > max)
            return Guard.returnOrThrow<number>(max, options?.throwIfOutOfRange);

        return value;
    }

    static enforcePattern(value: string, pattern: RegExp) {

        const globalPattern = new RegExp(pattern, "g");
        const match = value.matchAll(globalPattern);
        if (match === null || Array.from(match).length != value.length)
            throw new Error(`Invalid value ${value}`);
    }

    private static returnOrThrow<T>(value: T, alwaysThrow: boolean | undefined): T {

        if (alwaysThrow)
            throw new Error(`Invalid value ${value}`);

        return value;
    }
}
