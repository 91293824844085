import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '../../logger.service';
import { SearchService } from '../search.service';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'mvp-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {

    @Input()
    public showControls: boolean = false;
    @Input()
    public currentSearch: string = "";

    @Output()
    public filtersToggled = new EventEmitter();

    constructor(
        private router: Router,
        private settingsService: SettingsService,
        private logger: LoggerService) {
    }

    get showClear(): boolean {

        return this.settingsService.hasCurrentSearch;
    }

    public clearSearch(event: any): void {

        this.settingsService.clearSearch();
        this.router.navigate(["/galleries"]);
        event.stopPropagation();
    }

    public search(terms: any, event: any): void {

        this.logger.debug("search", `Searching for terms '${terms}'`);
        this.router.navigate(["/galleries/search", terms]);
        event.stopPropagation();
    }

    private toggleFilters(event: any) {

        this.filtersToggled.emit();
        event.stopPropagation();
    }

    public cleanSearchText(target: EventTarget | null) {

        const input = target as HTMLInputElement;
        if (input !== null)
            input.value = input.value.replace(SearchService.allowedCharactersRegex, "");;
    }
}
