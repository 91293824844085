import { IGalleryDto } from "./IGalleryDto";
import { ImageDto } from "./ImageDto";

export class GalleryDto implements IGalleryDto {
    identifier?: string | undefined;
    name: string = "";
    images: ImageDto[] = [];
    previewImageID?: string | undefined;

    constructor(data?: IGalleryDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
