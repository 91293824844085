import { Injectable } from '@angular/core';

import { AuthService, User } from '@auth0/auth0-angular';
import { environment } from '@env/environment';

import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class PicsAuthService {

    //TODO: convert user info to observables
    private _unknownUser: string = "<unknown>";
    public currentUserId: string = this._unknownUser;
    public isAdmin: boolean = false;

    constructor(
        private authService: AuthService,
        private logger: LoggerService) {

        this.authService.user$
            .subscribe((user: User | null | undefined) => {

                this.logger.debug("auth.service", `got user '${user?.email}'`);
                this.currentUserId = user?.sub ?? this._unknownUser;

                //ensure the admin flag is refreshed after a logout and login
                if (user)
                    this.refreshUser();
            });
    }

    private refreshUser() {

        this.authService.getAccessTokenSilently({ detailedResponse: true })
            .subscribe(token => {

                this.logger.debug("auth.service", `got token silently, scopes: ${token.scope}`);
                const payload = token.access_token.split('.')[1];
                const json = window.atob(payload);
                const claims = JSON.parse(json);
                this.isAdmin = claims.permissions.includes(environment.writeScope);
                this.logger.debug("auth.service", `user is admin = ${this.isAdmin}`);
            });
    }

    public logout() {

        this.authService.logout();
        this.logger.debug("auth.service", `logging out user '${this.currentUserId}'`);
    }
}
