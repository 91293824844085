<!-- fade in the backdrop just for the first image -->
<div class="transparent image-backdrop"
     [class.opaque]="showBackdrop"
     (click)="closeImage()">

    <mat-card id="previewCard"
              class="transparent image-card"
              [style.width.px]="cardWidth * 2"
              [ngStyle]="getZoomLocationStyles()"
              [class.opaque]="previewLoaded && cardWidth > 0"
              [class.zoomed]="cardWidthMaximised"
              (click)="nextImage($event)"
              (swiperight)="prevImage($event)"
              (swipeleft)="nextImage($event)"
              (swipeup)="closeImage(true)"
              (swipedown)="closeImage(true)"
              (pan)="pan($event)">

        <mat-card-header>
            <mat-card-title *ngIf="previewCaption">{{previewCaption}}</mat-card-title>
        </mat-card-header>

        <mvp-gallery-image *ngIf="previewImage"
                            mat-card-image
                            class="image-img"
                            [image]="previewImage"
                            [showThumbnail]="false"
                            (loadComplete)="loadComplete()"></mvp-gallery-image>

    </mat-card>

    <div class="image-nav"
         [class.transparent]="cardWidthMaximised"
         (click)="prevImage($event)">
        <span class="material-icons">chevron_left</span>
    </div>
    <div class="image-nav"
         [class.transparent]="cardWidthMaximised"
         (click)="nextImage($event)">
        <span class="material-icons">chevron_right</span>
    </div>
    <div class="image-nav"
         [class.off-canvas-left]="cardWidthMaximised"
         (click)="closeImage()">
        <span class="material-icons">clear</span>
    </div>
    <div class="image-nav"
         [class.off-canvas-right]="cardWidthMaximised"
         (click)="toggleFullScreen($event)">
        <span class="material-icons">{{cardWidthMaximised ? "close_fullscreen" : "open_in_full"}}</span>
    </div>

    <mvp-tagging *ngIf="!viewingTag"
                 class="tags link"
                 [class.transparent]="cardWidthMaximised"
                 [image]="previewImage"></mvp-tagging>

    <div *ngIf="viewingTag"
         class="gallery link"
         [class.transparent]="cardWidthMaximised"
         title="go to image in gallery"
         (click)="goToGallery($event)">
        <span class="material-icons">collections</span>
    </div>

</div>
