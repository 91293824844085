import { Component, OnDestroy } from '@angular/core';
import { LoggerService } from 'app/logger.service';

import { Subject, Observable, EMPTY } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    template: ""
})
export class SubscribingComponent implements OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        protected logger: LoggerService) {
    }

    ngOnDestroy() {

        this.logger.debug("subscribing-component", "destroy");

        //all subscriptions using the subscribe method below will now be unsubscribed
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public subscribe<T>(observable: Observable<T>, next: (value: T) => void) {

        observable
            .pipe(
                takeUntil(this.ngUnsubscribe))
            .subscribe(next);
    }
}
