import { Routes } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";

import { GalleryListComponent } from "./galleries/gallery-list/gallery-list.component";
import { GalleryComponent } from "./galleries/gallery/gallery.component";
import { ImageViewerComponent } from "./galleries/image-viewer/image-viewer.component";
import { SearchResultsComponent } from "./galleries/search-results/search-results.component";
import { TagComponent } from "./galleries/tag/tag.component";
import { TagListComponent } from "./galleries/tag-list/tag-list.component";
import { PublishComponent } from "./galleries/publish/publish.component";
import { AdminComponent } from "./admin/admin.component";

export const mvpRoutes: Routes = [

    { path: "galleries", component: GalleryListComponent, canActivate: [AuthGuard] },

    { path: "galleries/search", component: SearchResultsComponent, canActivate: [AuthGuard] },

    { path: "galleries/search/:terms", component: SearchResultsComponent, canActivate: [AuthGuard] },

    {
        path: "galleries/:id",
        component: GalleryComponent,
        canActivate: [AuthGuard],
        children: [

            { path: "images/:id", component: ImageViewerComponent, canActivate: [AuthGuard] }
        ]
    },

    { path: "tags", component: TagListComponent, canActivate: [AuthGuard] },

    {
        path: "tags/:name",
        component: TagComponent,
        canActivate: [AuthGuard],
        children: [

            { path: "images/:id", component: ImageViewerComponent, canActivate: [AuthGuard] }
        ]
    },

    { path: "publish", component: PublishComponent, canActivate: [AuthGuard] },

    { path: "admin", component: AdminComponent, canActivate: [AuthGuard] },

    //any route that doesn't match the above will use this default
    { path: "**", redirectTo: "/galleries", pathMatch: "full" }
]
