import { Component } from '@angular/core';
import { PublishService } from '../publish.service';
import { LoggerService } from '../../logger.service';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { AvailableGallery } from '../model/available-gallery';

@Component({
    selector: 'mvp-publish',
    templateUrl: './publish.component.html',
    styleUrls: ['./publish.component.scss']
})
export class PublishComponent {

    public galleries$: BehaviorSubject<Array<AvailableGallery>>;
    public availableRequested$: BehaviorSubject<boolean>;

    constructor(
        private publishService: PublishService,
        private logger: LoggerService) {

        this.galleries$ = this.publishService.available$;
        this.availableRequested$ = this.publishService.availableRequested$;
    }

    get noneAvailable(): Observable<boolean> {

        return this.galleries$.pipe(map(galleries => galleries.length === 0));
    }

    get availableRequested(): Observable<boolean> {

        return this.availableRequested$;
    }

    public publishGallery(gallery: AvailableGallery) {

        this.logger.debug("publish.component", `publishing gallery with location '${gallery.location}'`);
        this.publishService.publish(gallery)
            .subscribe(result =>
                this.logger.debug("publish.component", `publish gallery completed: '${result}'`));
    }
}
