<div *ngIf="viewingSingleYear"
     class="nav-back"
     (click)="showYears()">
    <span class="material-icons">arrow_back</span> back to years
</div>

<mvp-layout [title]="viewingSingleYear ? '' : title"
            [thumbnails$]="images$"
            [showCaptions]="showingYears && !showAll"
            (selected)="imageSelected($event)"></mvp-layout>

<router-outlet></router-outlet>
