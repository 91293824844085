import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "@env/environment";
import { catchError, map, of, take } from 'rxjs';

import { LoggerService } from './logger.service';
import { PicsAuthService } from './pics-auth.service';

const enum EventType {
    ImageViewed = 1,
    TagViewed = 2,
    SearchPerformed = 3
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private readonly eventsUrlRoot: string = `${environment.apiRoot}/events`;

    constructor(
        private authService: PicsAuthService,
        private httpClient: HttpClient,
        private logger: LoggerService) {
    }

    public sendImageViewedEvent(galleryId: number, imageId: number): void {

        this.logger.debug("analytics.service", `User ${this.authService.currentUserId} viewed ${galleryId}/${imageId}`);
        this.sendEvent(EventType.ImageViewed, { galleryId: galleryId, imageId: imageId });
    }

    private sendEvent(eventType: EventType, payload: {  }): void {

        const body = {
            userId: this.authService.currentUserId,
            eventType: eventType,
            eventPayload: payload
        };

        this.httpClient.post(this.eventsUrlRoot, body,
        {
            responseType: "text",
            observe: "response"
        })
            .pipe(
                map(response => {

                    this.logger.debug("analytics.service", `Returned ${response.status}`)
                }),
                catchError((err, _caught) => {

                    const message = err.error instanceof ErrorEvent ? err.error.message : `status ${err.status}`;
                    this.logger.debug("analytics.service", `Failed: ${message}`)
                    return of(false);
                })
            )
            .pipe(take(1))
            .subscribe();
    }
}
