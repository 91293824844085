<img *ngIf="reindexing" class="fixed-centre-postnav" src="../../../assets/aperture.gif" />

<mat-toolbar color="primary">
    <!-- must match exact route for the active class -->
    <a mat-icon-button
       [routerLink]="['/galleries']"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact:true}">
        <mat-icon>home</mat-icon>
        <span>galleries</span>
    </a>
    <a mat-icon-button
       [routerLink]="['/tags']"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact:true}">
        <mat-icon>local_offer</mat-icon>
        <span>tags</span>
    </a>
    <a *ngIf="userIsAdmin"
       mat-icon-button
       [routerLink]="['/publish']"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact:true}">
        <mat-icon>publish</mat-icon>
        <span>publish</span>
    </a>
    <a *ngIf="hasCurrentSearch"
       mat-icon-button
       [routerLink]="['/galleries/search', currentSearchTerms]"
       [queryParams]="currentSearchParameters"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact:true}">
        <mat-icon>find_replace</mat-icon>
        <span>current search</span>
    </a>
    <button *ngIf="showReindex"
            mat-icon-button (click)="reindex()">
        <mat-icon>sync</mat-icon>
        <span>re-index</span>
    </button>
    <button *ngIf="showRefreshAvailable"
            mat-icon-button (click)="refreshAvailable()">
        <mat-icon>refresh</mat-icon>
        <span>refresh available</span>
    </button>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="logout()">
        <mat-icon class="logout">exit_to_app</mat-icon>
        <span>logout</span>
    </button>
</mat-toolbar>
