import { Component, DoCheck, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EMPTY, Observable } from "rxjs";
import { tap, map } from "rxjs/operators";

import { Thumbnail } from '../model/thumbnail';
import { GalleryService } from '../gallery.service';
import { PreviewableGallery } from '../model/previewable-gallery';

@Component({
    selector: 'mvp-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, DoCheck {

    public title: string = "";
    public images$: Observable<Array<Thumbnail>> = EMPTY;

    private _title: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private galleryService: GalleryService) {
    }

    ngOnInit() {

        this.images$ =
            this.galleryService.getGallery(this.route.snapshot.params["id"])
                .pipe(
                    tap(g => this._title = `${this.getGalleryDate(g)} - ${g.name}`),
                    map(g => {

                        if (g && g.images)
                            return g.images.map(i => new Thumbnail(i));

                        return [];
                    }));
    }

    ngDoCheck() {

        //must set this after content has initialised to avoid expression has changed...
        //doing so in here forces change detection (https://blog.angular-university.io/angular-debugging/)
        this.title = this._title;
    }

    private getGalleryDate(g: PreviewableGallery): string {

        const date = new Date(g.images[0]?.date);
        const options: Intl.DateTimeFormatOptions = { month: "long", year: "numeric" };
        if (date)
            return date.toLocaleDateString("default", options).toLowerCase();

        return "date unknown!";
    }

    public imageSelected(thumbnail: Thumbnail): void {

        this.router.navigate(["images", thumbnail.id], { relativeTo: this.route });
    }
}
