import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, convertToParamMap } from '@angular/router';

import { PicsAuthService } from '../pics-auth.service';
import { SettingsService } from '../galleries/settings.service';
import { SearchResultsComponent } from 'app/galleries/search-results/search-results.component';
import { filter, map } from 'rxjs';
import { GalleryComponent } from '../galleries/gallery/gallery.component';
import { PublishService } from '../galleries/publish.service';
import { LoggerService } from '../logger.service';
import { PublishComponent } from '../galleries/publish/publish.component';

@Component({
    selector: 'mvp-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent {

    public reindexing: boolean = false;

    private galleryId: string = "";
    private isGallery: boolean = false;
    private isPublish: boolean = false;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: PicsAuthService,
        private publishService: PublishService,
        private settingsService: SettingsService,
        private logger: LoggerService) {

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.route))
            .pipe(
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }))
            .subscribe(route => {

                this.galleryId = route.snapshot.params["id"];
                this.isGallery =
                    this.galleryId !== undefined &&
                    route.snapshot.component === GalleryComponent;
                this.isPublish =
                    this.galleryId === undefined &&
                    route.snapshot.component === PublishComponent;
            });
    }

    get userIsAdmin(): boolean {

        return this.authService.isAdmin;
    }

    get hasCurrentSearch(): boolean {

        return this.settingsService.hasCurrentSearch;
    }

    get currentSearchTerms(): string {

        if (this.hasCurrentSearch)
            return this.settingsService.currentSearchRequest?.terms ?? "";

        return "";
    }

    get currentSearchParameters() {

        return SearchResultsComponent.CreateUseCurrentSearchParams();
    }

    get showReindex(): boolean {

        return this.isGallery && this.userIsAdmin;
    }

    public reindex(): void {

        if (this.galleryId) {

            this.reindexing = true;
            this.publishService.reindex(this.galleryId)
                .subscribe({
                    next: result => {

                        this.logger.debug("nav.component", `re-index of gallery id ${this.galleryId}=${result}`);
                        this.reindexing = false;
                    },
                    error: (err) => {

                        this.logger.debug("nav.component", `re-index of gallery id ${this.galleryId} failed with ${err}`);
                        this.reindexing = false;
                    }
                });
        }
    }

    get showRefreshAvailable(): boolean {

        return this.isPublish;
    }

    public refreshAvailable(): void {

        this.publishService.refreshAvailableGalleries();
    }

    public logout(): void {

        this.authService.logout();
    }
}
