import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

import { LoggerService } from '../logger.service';
import { IImageDto } from 'app/galleries/model/IImageDto';
import { ITag } from './model/itag';
import { Thumbnail } from '../galleries/model/thumbnail';

@Injectable({
    providedIn: "root"
})
export class TaggingService {

    private readonly tagsUrlRoot: string = `${environment.apiRoot}/tags`;

    public filteredImages?: Observable<Array<Thumbnail>>;

    private availableTags$?: Observable<Array<ITag>>;
    private availableTags: Array<ITag> | null = null;

    private tag$?: Observable<ITag>;
    private tag: ITag | null = null;
    private currentTagName: string = "";

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService) {
    }

    private tagsUrl(tag: string): string {

        return `${this.tagsUrlRoot}/${tag}`;
    }

    public getTags(): Observable<Array<ITag>> {

        this.logger.debug("tagging.service", "Get tags");

        //TODO: re-request after certain period
        if (this.availableTags) {

            this.logger.debug("tagging.service", "Returning cached tags");
            return of(this.availableTags);
        }

        if (this.availableTags$) {

            this.logger.debug("tagging.service", "Pending request");
            return this.availableTags$;
        }

        this.logger.debug("tagging.service", "Requesting available tags");
        const options: any = {
            observe: "response",
            responseType: "json",
            headers: new HttpHeaders({
                "Accept": "application/hal+json"
            })
        };
        this.availableTags$ = this.httpClient.get(this.tagsUrlRoot, options)
            .pipe(
                map((response: any) => {

                    this.logger.debug("tagging.service", "Got available tags");

                    let tags: Array<ITag> = [];
                    if (response && response.body && response.body._embedded &&
                        response.body._embedded.tags && response.body._embedded.tags.length > 0) {

                        tags = response.body._embedded.tags;
                    }

                    return tags;
                }),
                shareReplay());

        this.logger.debug("tagging.service", "Subscribing");
        this.availableTags$
            .subscribe({
                next: tags => this.availableTags = tags.sort(),
                complete: () => this.availableTags$ = undefined
            });

        this.logger.debug("tagging.service", "Done");
        return this.availableTags$;
    }

    getTag(name: string): Observable<ITag> {

        this.logger.debug("tagging.service", "Get tag");

        if (this.tag &&
            this.tag.name === name) {

            this.logger.debug("tagging.service", "Exact cache hit");
            return of(this.tag);
        }

        if (this.tag$ && this.currentTagName === name) {

            this.logger.debug("tagging.service", "Pending cache hit");
            return this.tag$;
        }

        this.logger.debug("tagging.service", "Requesting tag");
        this.currentTagName = name;
        this.tag$ = this.httpClient.get<ITag>(this.tagsUrl(name), {
            observe: "body",
            responseType: "json",
            headers: new HttpHeaders({
                "Accept": "application/hal+json"
            })
        }).pipe(shareReplay());

        this.logger.debug("tagging.service", "Subscribing");
        this.tag$
            .subscribe({
                next: tag => this.tag = tag,
                complete: () => this.tag$ = undefined
            });

        this.logger.debug("tagging.service", "Done");
        return this.tag$;
    }

    public create(tagName: string, galleryId: string, image: IImageDto): Observable<boolean> {

        return this.toggleTag(this.tagsUrlRoot, "POST", tagName, galleryId, image, () => {

            var tag = {
                name: tagName,
                canonicalImage: image,
                images: [ image ]
            };

            image.tags.push(tagName);
            image.tags = image.tags.sort();

            if (this.availableTags === null)
                this.availableTags = [];

            this.availableTags.push(tag);
            this.availableTags = this.availableTags.sort();
        });
    }

    public add(tag: string, galleryId: string, image: IImageDto): Observable<boolean> {

        return this.toggleTag(`${this.tagsUrl(tag)}/images`, "POST", tag, galleryId, image, () => {

            image.tags.push(tag);
            image.tags = image.tags.sort();
        });
    }

    public remove(tag: string, galleryId: string, image: IImageDto): Observable<boolean> {

        return this.toggleTag(`${this.tagsUrl(tag)}/images`, "DELETE", tag, galleryId, image, () => {

            const index = image.tags.indexOf(tag);
            if (index >= 0)
                image.tags.splice(index, 1);
        });
    }

    private toggleTag(url: string, verb: string, tag: string,
        galleryId: string, image: IImageDto, success: () => void): Observable<boolean> {

        const target = `${verb} tag '${tag}' from ${galleryId}.${image.id}`;
        this.logger.debug("tagging.service", `Request to ${target}`);
        const body = {
            name: tag,
            galleryId: galleryId,
            imageId: image.id
        };

        return this.httpClient.request(verb, url,
            {
                body: body,
                responseType: "text",
                observe: "response"
            })
            .pipe(
                map(response => {

                    this.logger.debug("tagging.service", `${target} returned ${response.status}`)
                    if (!response.ok)
                        return false;

                    success();
                    return response.ok;
                }),
                catchError((err, _caught) => {

                    const message = err.error instanceof ErrorEvent ? err.error.message : `status ${err.status}`;
                    this.logger.debug("tagging.service", `${target} failed: ${message}`)
                    return of(false);
                })
            );
    }
}
