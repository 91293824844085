import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../logger.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

    defaultFoods: Array<string> = [];

    constructor(
        private logger: LoggerService) {
    }

    ngOnInit(): void {

        //load defaults from backend
        const foods = [
            "Milk",
            "Egg",
            "Tomato",
            "Oats",
            "Sultanas",
            "Bread (vollkorn mix)",
            "Apple",
            "Banana",
            "Yoghurt",
            "Peanuts (oil/salt)",
            "Walnuts",
            "Sunflower seeds",
            "Cheddar cheese",
            "Avocado",
        ];

        this.defaultFoods = foods.sort();
    }

    public logFood(food: string, weight: string) {

        this.logger.debug("admin", `Logging food ${food}: ${weight}`)
    }
}
