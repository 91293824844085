<div class="layout-container">

    <div *ngIf="title" class="title">
        {{title}}
    </div>

    <!-- show loading gif until all thumbnails load but still allow them to be clicked -->
    <img *ngIf="loadedThumbnails < totalThumbnails" class="fixed-centre-postnav" src="../../../assets/aperture.gif" />

    <div class="layout-row" *ngFor="let row of rows$ | async">

        <!-- ngStyle and ngClass preferred over style.x and class.x when binding multiple -->
        <mat-card *ngFor="let thumbnail of row"
                  class="layout-slot transparent"
                  [class.opaque]="thumbnail.loaded"
                  [ngStyle]="getCardStyles(thumbnail)"
                  (click)="thumbnailClicked(thumbnail)">

            <!-- moved title out of header to allow overflow/wrap to be set -->
            <mat-card-title class="mat-card-header"
                            title="{{formatDate(thumbnail.date)}}"
                            *ngIf="showCaptions">{{thumbnail.caption}}</mat-card-title>

            <mat-card-content>

                <!-- TODO: paging to images that are already loaded doesn't fade nicely
                    so use (load)="someMethod()" which has a minimum ~500ms fade in time -->
                <mvp-gallery-image [image]="thumbnail"
                                   class="transparent"
                                   [class.opaque]="thumbnail.loaded"
                                   [title]="thumbnail.caption"
                                   (loadComplete)="thumbnailLoaded(thumbnail)"></mvp-gallery-image>

            </mat-card-content>

        </mat-card>

    </div>

</div>
