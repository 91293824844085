import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from "@env/environment";

import { Observable, EMPTY } from 'rxjs';
import { map } from "rxjs/operators";

import { LoggerService } from '../logger.service';
import { PageOf } from './model/page-of';
import { SearchResultDocument } from './model/search-result-document';
import { SearchResult } from './model/search-result';
import { FacetValue } from './model/facet-value';
import { SearchRequest } from './model/search-request';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    private readonly searchUrl: string = `${environment.searchRoot}/index`;
    private readonly pageSize: number = 10;

    public static readonly allowedCharactersRegex: RegExp = /[^A-Za-z0-9- ]/g;

    public currentSearch: string = "";
    public result$: Observable<PageOf<SearchResultDocument>> = EMPTY;

    public tagFilters$: Observable<Array<string>> = EMPTY;
    public yearFilters$: Observable<Array<number>> = EMPTY;
    public monthFilters$: Observable<Array<number>> = EMPTY;

    public tags$: Observable<Array<FacetValue>> = EMPTY;
    public years$: Observable<Array<FacetValue>> = EMPTY;
    public months$: Observable<Array<FacetValue>> = EMPTY;

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService) {
    }

    public search(request: SearchRequest): void {

        this.logger.debug("search.service", `Searching for '${JSON.stringify(request)}'`);
        this.currentSearch = request.terms || "";

        let search = `${this.searchUrl}?page=${request.page || 1}`;
        if (this.currentSearch)
            search += "&" + this.currentSearch
                .split(" ")
                .filter(t => t)
                .map(t => `q=${t.replace(SearchService.allowedCharactersRegex, "")}`)
                .join("&");
        search += this.AddFilters(request.tagFilters, "t");
        search += this.AddFilters(request.yearFilters, "y");
        search += this.AddFilters(request.monthFilters, "m");

        this.logger.debug("search.service", `Searching with ${search}`);
        let searchResult$ = this.httpClient.get<SearchResult>(search,
            {
                observe: "response",
                responseType: "json",
                headers: { "Accept": "application/json" }
            });

        this.result$ = searchResult$
            .pipe(
                map((response: HttpResponse<SearchResult>) => {

                    const result = response?.body;
                    if (!response || !response.ok || !result) {

                        this.logger.debug("search.service", "Failed to get result from search service");
                        if (response)
                            this.logger.debug("search.service", `Search failed with code ${response.status} (${response.statusText})`);
                        return new PageOf<SearchResultDocument>(1, this.pageSize, 0, new Array<SearchResultDocument>());
                    }

                    return new PageOf<SearchResultDocument>(result.page, this.pageSize, result.totalCount, result.results);
                }));

        this.tagFilters$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.tagFilters || []));
        this.yearFilters$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.yearFilters || []));
        this.monthFilters$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.monthFilters || []));

        this.tags$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.tags || []));
        this.years$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.years || []));
        this.months$ = searchResult$.pipe(
            map((response: HttpResponse<SearchResult>) =>
                response?.body?.months || []));
    }

    private AddFilters<T>(filters: T[], parameter: string) {

        if (!filters || filters.length === 0)
            return "";

        const join = `&${parameter}=`;
        return join + filters.join(join);
    }
}
