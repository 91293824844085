import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

Sentry.init({
    dsn: "https://bda98900e338427ba551cc0118aefaeb@o286351.ingest.sentry.io/1518893",
    enabled: environment.production,
    integrations: [

        new BrowserTracing({
            tracingOrigins: [ environment.apiRoot ],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    //1.0 = 100% of transactions for performance monitoring
    tracesSampleRate: environment.sentryTraceSampleRate
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
