export const environment = {

    production: false,
    loggingOn: true,
    LOCAL_apiRoot: "http://localhost:7073",
    LOCAL_apiWriteRoot: "http://localhost:7071",
    LOCAL_searchRoot: "http://localhost:7075",
    OLD_apiRoot: "https://mv-pics-q-functions.azurewebsites.net",
    OLD_apiWriteRoot: "https://mv-pics-c-functions.azurewebsites.net",
    OLD_searchRoot: "https://mv-pics-s-functions.azurewebsites.net",
    apiRoot: "https://api-dev.mattvigg.co.uk",
    searchRoot: "https://api-dev.mattvigg.co.uk/search",

    galleriesPath: "/galleries",

    sentryTraceSampleRate: 1,

    AuthDomain: "mattvigg.eu.auth0.com",
    AuthClientId: "l1A7GliTx6U8etYMcYFPtBNi8A5M5Qtj",
    AuthApiIdentifier: "api-dev.mattvigg.co.uk",
    readScope: "read-galleries",
    writeScope: "write-galleries",
    searchScope: "query-search"
};
