import { Injectable } from '@angular/core';

import { environment } from "@env/environment";

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    debug(context: string, message: string) {

        this.logMessages(context, message);
    }

    error(error: Error) {

        this.logMessages("Error!", `${error.name} ${error.message} ${error.stack}`);
    }

    private logMessages(context: string, message: string) {

        if (environment.loggingOn)
            console.log(`${context}: ${message}`);
    }
}
