import { Injectable } from '@angular/core';

import { LoggerService } from 'app/logger.service';
import { SearchRequest } from './model/search-request';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(
        private logger: LoggerService) {
    }

    public pageSizeOptions: Array<number> = [10, 20];

    public readonly defaultPageSize: number = 10;

    private _lastPage: number = 1;
    get lastPage(): number {

        return this._lastPage;
    }
    set lastPage(page: number) {

        this.logger.debug("settings-service", `Attempt set last page: ${page}`);

        if (page < 0)
            page = 1;
        this._lastPage = page;
    }

    private _lastPageSize: number = this.defaultPageSize;
    get lastPageSize(): number {

        return this._lastPageSize;
    }
    set lastPageSize(pageSize: number) {

        this.logger.debug("settings-service", `Attempt set page size: ${pageSize}`);

        if (!this.pageSizeOptions.includes(pageSize))
            pageSize = this.defaultPageSize;

        this._lastPageSize = pageSize;
    }

    private _currentSearchRequest: SearchRequest | null = null;
    get currentSearchRequest(): SearchRequest | null {

        return this._currentSearchRequest;
    }
    set currentSearchRequest(request: SearchRequest | null) {

        this.logger.debug("settings-service", `Current search ${JSON.stringify(request)}`);

        this._currentSearchRequest = request;
    }

    get hasCurrentSearch(): boolean {

        if (!this._currentSearchRequest)
            return false;

        const hasTerms =
            this._currentSearchRequest.terms !== null &&
            this._currentSearchRequest.terms !== "";
        return hasTerms ||
            this._currentSearchRequest.page > 1 ||
            this._currentSearchRequest.tagFilters.length > 0 ||
            this._currentSearchRequest.monthFilters.length > 0 ||
            this._currentSearchRequest.yearFilters.length > 0;
    }

    public clearSearch(): void {

        this._currentSearchRequest = null;
    }
}
