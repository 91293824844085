import { IGalleryDto } from "./IGalleryDto";
import { IImageDto } from "./IImageDto";
import { GalleryDto } from "./GalleryDto";
import { ImageDto } from "./ImageDto";

export class PreviewableGallery extends GalleryDto {

    constructor(gallery: IGalleryDto) {

        super(gallery);
    }

    public get previewImage(): IImageDto {

        if (!this.images ||
            this.images.length === 0 ||
            !this.previewImageID)
            return new ImageDto();

        return this.images.find(i => i.id === this.previewImageID) ?? new ImageDto();
    }
}
