<!--<div class="options">
    <mat-checkbox [(ngModel)]="showCaptions">Show image captions</mat-checkbox>
</div>-
<mvp-layout [thumbnails$]="images$"
            [showCaptions]="showCaptions"
            (selected)="imageSelected($event)"></mvp-layout>-->

<mvp-layout [title]="title"
            [thumbnails$]="images$"
            (selected)="imageSelected($event)"></mvp-layout>

<router-outlet></router-outlet>
