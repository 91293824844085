import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { Observable, Subject, interval } from "rxjs";
import { map, combineLatest, sample } from 'rxjs/operators';

import { SubscribingComponent } from 'app/subscribing/subscribing.component';
import { GalleryService } from "../gallery.service";
import { IImageDto } from '../model/IImageDto';
import { TaggingService } from '../tagging.service';
import { LoggerService } from 'app/logger.service';
import { AnalyticsService } from 'app/analytics.service';

@Component({
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
    //TODO: check if bug fixed: https://github.com/angular/angular/issues/16670
    //navigating away from this component will leave styles in place
    //encapsulation: ViewEncapsulation.None
})
export class ImageViewerComponent extends SubscribingComponent implements OnInit {

    public showBackdrop: boolean = false;
    public viewingTag: boolean = false;

    public previewImage?: IImageDto;
    public previewCaption: string = "";
    public previewLoaded: boolean = false;
    public cardWidth: number = 0;
    public cardWidthMaximised = false;

    private parentRoute?: string;
    private parentIdentifier: string = "";

    private prevImageIdentifier?: string;
    private nextImageIdentifier?: string;

    private resize$: Subject<void>;
    private previousWidth: number = 0;

    private readonly zoomTopOrLeft: number = 100;
    private readonly zoomBottomOrRight: number = 0;

    private zoomX: number = 0;
    private zoomY: number = 0;

    private readonly cardExtraSpaceY = 25;
    private readonly cardDisplayPercentage: number = 0.99;

    private readonly arrowUpCode: string = "ArrowUp";
    private readonly arrowDownCode: string = "ArrowDown";
    private readonly arrowRightCode: string = "ArrowRight";
    private readonly arrowLeftCode: string = "ArrowLeft";
    private readonly escapeCode: string = "Escape";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private galleryService: GalleryService,
        private taggingService: TaggingService,
        private analyticsService: AnalyticsService,
        logger: LoggerService) {

        super(logger);

        this.resize$ = new Subject<void>();
    }

    ngOnInit() {

        //make sure we're at the top of the document before removing scroll bars
        scroll(0, 0);
        document.body.className = "no-scroll";
        this.resetZoom();

        let images$: Observable<Array<IImageDto>> | undefined;
        this.parentRoute = this.route.parent?.snapshot.url[0].path;
        if (this.parentIsGallery()) {

            this.parentIdentifier = this.route.parent?.snapshot.params["id"];
            this.viewingTag = false;
            images$ = this.galleryService.getGallery(this.parentIdentifier)
                .pipe(map(gallery => gallery.images));
        }
        else {

            this.parentIdentifier = this.route.parent?.snapshot.params["name"];
            this.viewingTag = true;
            images$ = this.taggingService.filteredImages;
            if (images$ === undefined) {

                this.closeImage();
                return;
            }
        }

        this.showBackdrop = true;

        this.subscribe(
            images$
                .pipe(
                    combineLatest(this.route.params, (images, params) => {

                        //projection of the gallery and current route which returns the next image
                        if (images.length === 0)
                            return null;

                        //find the requested image and go to the start if we didn't find the id
                        let index = images.findIndex(image => image.id === params["id"]);
                        if (index < 0)
                            index = 0;

                        //get the prev/next image id wrapping if necessary
                        this.prevImageIdentifier =
                            index === 0 ?
                                images[images.length - 1].id :
                                images[index - 1].id;
                        this.nextImageIdentifier =
                            images.length === index + 1 ?
                                images[0].id :
                                images[index + 1].id;

                        return images[index];
                    })),
            image => {

                //TODO: set standard image not found
                if (!image)
                    return;

                //found the image so get its source
                this.previewImage = image;
                //TODO: image captions: this.previewCaption = image.caption;

                //calc height based on max factor and then check if viewport can fit width
                this.setCardWidthForImage(image);
            });

        this.subscribe(
            this.resize$
                .asObservable()
                .pipe(
                    sample(interval(1000))),
            interval => this.setCardWidthForImage(this.previewImage));
    }

    @HostListener('window:resize')
    onResize() {

        //check that the width actually changed
        if (this.previousWidth !== window.innerWidth)
            this.resize$.next();
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyDown(event: KeyboardEvent) {

        if (event.code === this.escapeCode) {

            this.closeImage();
            return;
        }

        if (this.cardWidthMaximised) {

            if (event.code === this.arrowUpCode)
                this.moveY(5);
            else if (event.code === this.arrowDownCode)
                this.moveY(-5);
            else if (event.code === this.arrowRightCode)
                this.moveX(-5);
            else if (event.code === this.arrowLeftCode)
                this.moveX(5);
            return;
        }

        if (event.code === this.arrowRightCode)
            this.nextImage(event);
        else if (event.code === this.arrowLeftCode)
            this.prevImage(event);
    }

    pan(panEvent: any) {

        if (!panEvent || !panEvent.deltaX || !panEvent.deltaY)
            return;

        const speedFactor = 0.004;

        this.moveX(panEvent.deltaX * speedFactor);
        this.moveY(panEvent.deltaY * speedFactor);
    }

    moveX(offset: number) {

        this.zoomX = this.sumZoomToMax(this.zoomX, offset);
    }

    moveY(offset: number) {

        this.zoomY = this.sumZoomToMax(this.zoomY, offset);
    }

    private parentIsGallery(): boolean {

        return this.parentRoute === "galleries";
    }

    private sumZoomToMax(first: number, second: number): number {

        const result = first + second;

        if (result > this.zoomTopOrLeft)
            return this.zoomTopOrLeft;
        if (result < this.zoomBottomOrRight)
            return this.zoomBottomOrRight;

        return result;
    }

    private setCardWidthForImage(image?: IImageDto): void {

        //if a resize happens when images are still loading then return
        if (!image)
            return;

        this.previousWidth = window.innerWidth;

        const imageRatio = 3 / 2;
        let height = (window.innerHeight - this.cardExtraSpaceY) * this.cardDisplayPercentage;
        let width = image.isPortrait ? height / imageRatio : height * imageRatio;

        //assume the viewport is always at least a 3 x 2 ratio
        if (width <= window.innerWidth) {

            //image fits so set the width which will display the image
            this.cardWidth = width;
        }
        else {

            //image doesn't fit so assume it will fit when we maximise the width
            this.cardWidth = window.innerWidth * this.cardDisplayPercentage;
        }
    }

    loadComplete() {

        this.logger.debug("image-viewer", "Image load complete");
        if (this.parentIsGallery() && this.previewImage?.id !== null)
            this.analyticsService.sendImageViewedEvent(
                parseInt(this.parentIdentifier),
                parseInt(this.previewImage?.id ?? "0"));
        this.previewLoaded = true;
    }

    prevImage(event: any) {

        this.moveImage(event, this.prevImageIdentifier);
    }

    nextImage(event: any) {

        if (this.cardWidthMaximised) {

            event.stopPropagation();
            return;
        }

        this.moveImage(event, this.nextImageIdentifier);
    }

    private moveImage(event: any, imageIdentifier?: string) {

        //TODO: adjust this when there is actual waiting time for the next image to load?
        //let the image almost fade out before letting the next one fade in
        this.previewLoaded = false;
        this.resetZoom();
        window.setTimeout(() =>
            this.router.navigate(["../", imageIdentifier], { relativeTo: this.route }), 650);

        if ("stopPropagation" in event)
            event.stopPropagation();
    }

    private resetZoom() {

        this.cardWidthMaximised = false;
        const centre = (this.zoomTopOrLeft - this.zoomBottomOrRight) / 2;
        this.zoomX = centre;
        this.zoomY = centre;
    }

    toggleFullScreen(event: any) {

        this.cardWidthMaximised = !this.cardWidthMaximised;
        event.stopPropagation();
    }

    getZoomLocationStyles() {

        if (!this.cardWidthMaximised)
            return {};

        return {
            "top": `${this.zoomY}%`,
            "left": `${this.zoomX}%`
        };
    }

    closeImage(fromGesture: boolean = false) {

        if (fromGesture && this.cardWidthMaximised) {

            event?.stopPropagation();
            return;
        }

        //allowing scrolling again
        document.body.className = "";

        this.previewLoaded = false;
        this.showBackdrop = false;
        window.setTimeout(() =>
            this.router.navigate([`/${this.parentRoute}`, this.parentIdentifier]), 1000);

        if (event !== undefined && "stopPropagation" in event)
            event.stopPropagation();
    }

    goToGallery(event: any) {

        this.router.navigateByUrl(this.previewImage?.url ?? "");
        event.stopPropagation();
    }
}
