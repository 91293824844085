import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';

import { Thumbnail } from '../../galleries/model/thumbnail';
import { TaggingService } from '../tagging.service';
import { SubscribingComponent } from 'app/subscribing/subscribing.component';
import { LoggerService } from '../../logger.service';
import { Guard } from 'app/guard';

@Component({
    selector: 'mvp-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent extends SubscribingComponent implements OnInit {

    public images$: BehaviorSubject<Array<Thumbnail>>;
    public title: string = "";

    public showingYears: boolean = true;
    public showAll: boolean = false;

    private yearlyImages: { [year: number]: Array<Thumbnail> } = {};

    private get tagName(): string {

        return this.route.snapshot.params["name"];
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private taggingService: TaggingService,
        logger: LoggerService) {

        super(logger);

        this.images$ = new BehaviorSubject<Array<Thumbnail>>([]);
    }

    ngOnInit() {

        this.logger.debug("tag.component", "Init");

        this.subscribe(
            this.taggingService.getTag(this.tagName),
            tag => {

                this.title = tag.name;
                this.yearlyImages = {};

                tag.images
                    .map(i => {

                        const year = new Date(i.date).getFullYear();

                        if (!(year in this.yearlyImages))
                            this.yearlyImages[year] = new Array<Thumbnail>();

                        i.id = (year * 1000 + this.yearlyImages[year].length).toString();
                        this.yearlyImages[year].push(new Thumbnail(i, null, year.toString()));
                    });

                this.showYears();
            });
    }

    public imageSelected(thumbnail: Thumbnail): void {

        const year: number = Guard.limitInteger(thumbnail.caption ?? "", 0, 2999);
        this.logger.debug("tag.component", `Attempting to find images for tag ${this.tagName} for year ${year}`);

        const values = Object.values(this.yearlyImages);
        let thumbnails: Array<Thumbnail> =
            values.length === 1 ?
                values[0] :
                this.yearlyImages[year].map(t => t.clone());

        if (this.showingYears) {

            this.images$.next(thumbnails);
            this.showingYears = false;
        }
        else {

            this.taggingService.filteredImages = of(thumbnails);
            this.router.navigate(["images", thumbnail.id], { relativeTo: this.route });
        }
    }

    public showYears() {

        var values = Object.values(this.yearlyImages);
        if (values.length === 1) {

            this.images$.next(values[0].map(t => t.clone()));
            this.showAll = true;
            this.showingYears = false;
        }
        else {

            this.images$.next(values.map(y => y[0]).sort().reverse());
            this.showingYears = true;
        }
    }

    public get viewingSingleYear(): boolean {

        return !this.showingYears && !this.showAll;
    }
}
