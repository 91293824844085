import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { PageEvent } from "@angular/material/paginator";

import { EMPTY, Observable } from "rxjs";
import { map, combineLatest } from "rxjs/operators";

import { environment } from "@env/environment";
import { SubscribingComponent } from "app/subscribing/subscribing.component";
import { SearchService } from "../search.service";
import { LoggerService } from "../../logger.service";
import { PageOf } from "../model/page-of";
import { SearchResultDocument } from "../model/search-result-document";
import { IImageDto } from "../model/IImageDto";
import { ImageDto } from "../model/ImageDto";
import { Thumbnail } from "../model/thumbnail";
import { FacetValue } from "../model/facet-value";
import { SearchRequest } from "../model/search-request";
import { SettingsService } from "../settings.service";

@Component({
    selector: 'mvp-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent extends SubscribingComponent implements OnInit {

    public currentSearch: string = "";

    public results$: Observable<PageOf<SearchResultDocument>> = EMPTY;
    public resultPreviews$: Observable<Array<Thumbnail>> = EMPTY;

    public tags: Array<FacetValue> = [];
    public years: Array<FacetValue> = [];
    public months: Array<FacetValue> = [];

    protected filtersSelected: boolean = false;
    protected showFilters: boolean = true;

    private static parameterUseCurrent: string = "uc";
    private currentPage: number = 1;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private searchService: SearchService,
        private settingsService: SettingsService,
        logger: LoggerService) {

        super(logger);
    }

    ngOnInit() {

        this.logger.debug("search-results", "Init");
        const useCurrent =
            this.useCurrentSearch() &&
            this.settingsService.hasCurrentSearch;
        this.runSearch(useCurrent)
    }

    public static CreateUseCurrentSearchParams(): Params {

        let search: Params = {};
        search[SearchResultsComponent.parameterUseCurrent] = 1;

        return search;
    }

    private useCurrentSearch(): boolean {

        return this.route.snapshot.queryParams[SearchResultsComponent.parameterUseCurrent] !== undefined;
    }

    public paged(pageEvent: PageEvent) {

        this.currentPage = pageEvent.pageIndex + 1;
        this.runSearch();
    }

    public runSearch(useCurrent: boolean = false) {

        if (useCurrent && !this.settingsService.hasCurrentSearch)
            useCurrent = false;

        this.logger.debug("search-results", "Running search");
        this.subscribe(this.route.params,
            params => {

                const currentSearchRequest = this.settingsService.currentSearchRequest;
                this.currentSearch = (useCurrent ?
                    currentSearchRequest?.terms :
                    params["terms"]) || "";
                const request: SearchRequest = useCurrent && currentSearchRequest !== null ?
                    currentSearchRequest :
                    this.createSearchRequest();

                this.logger.debug("search-results", `Current search '${this.currentSearch}'`);
                this.searchService.search(request);

                this.results$ = this.searchService.result$;
                this.resultPreviews$ = this.results$.pipe(
                    map(results => {

                        var thumbnails: Array<Thumbnail> = new Array<Thumbnail>();
                        for (let document of results.currentPage) {

                            let identifier = document.galleryID.toString();
                            if (document.isImage)
                                identifier = `${identifier}:${document.imageID}`;
                            thumbnails.push(new Thumbnail(this.createImage(document), identifier));
                        }
                        return thumbnails;
                    }));

                this.subscribe(
                    this.searchService.tags$
                        .pipe(
                            combineLatest(this.searchService.tagFilters$, (tags, filters) =>
                                tags.map(t => {

                                    t.selected = filters.includes(t.value);
                                    return t;
                                }))),
                    tags => this.tags = tags
                        .sort((a, b) => a.value < b.value ? -1 : 1));
                this.subscribe(
                    this.searchService.years$
                        .pipe(
                            combineLatest(this.searchService.yearFilters$, (years, filters) =>
                                years.map(y => {

                                    y.selected = filters.includes(Number.parseInt(y.value));
                                    return y;
                                }))),
                    years => this.years = years
                        .sort((a, b) => a.value < b.value ? -1 : 1));
                this.subscribe(
                    this.searchService.months$
                        .pipe(
                            combineLatest(this.searchService.monthFilters$, (months, filters) =>
                                months
                                    .sort((a, b) => Number.parseInt(a.value) - Number.parseInt(b.value))
                                    .map(m => {

                                        const month: number = Number.parseInt(m.value);
                                        m.value = this.getMonth(month).toString();
                                        m.selected = filters.includes(month);
                                        return m;
                                    }))),
                    months => this.months = months);
            });
    }

    private createSearchRequest(): SearchRequest {

        const request: SearchRequest = {

            terms: this.currentSearch,
            tagFilters: this.getSelectedFilters<string>(this.tags),
            yearFilters: this.getSelectedFilters<number>(this.years),
            monthFilters: this.getSelectedFilters<string>(this.months).map(m => <number>this.getMonth(m)),
            page: this.currentPage
        };
        this.settingsService.currentSearchRequest = request;

        return request;
    }

    private getSelectedFilters<T>(filters: Array<FacetValue>): Array<T> {

        if (!filters)
            return new Array<T>();

        return filters
            .filter(t => t.selected)
            .map(t => <T><unknown>t.value)
    }

    public resultSelected(thumbnail: Thumbnail) {

        this.logger.debug("search-results", `gallerySelected ${thumbnail.parentIdentifier} ${thumbnail.url}`);
        let ids = thumbnail.parentIdentifier?.split(":");
        if (ids?.length === 1)
            this.router.navigate(["/galleries", thumbnail.parentIdentifier]);
        else
            this.router.navigateByUrl(thumbnail.url);
    }

    private createImage(document: SearchResultDocument): IImageDto {

        let image = new ImageDto();
        if (!document || !document.galleryID || !document.imageID)
            return image;

        image.id = document.imageID.toString();
        image.date = document.date;
        image.url = `${environment.galleriesPath}/${document.galleryID}/images/${document.imageID}`;
        image.isPortrait = document.isPortrait;

        return image;
    }

    protected toggleFilter(filter: FacetValue): void {

        filter.selected = !filter.selected;
        this.filtersSelected =
            this.tags.some(t => t.selected) ||
            this.years.some(y => y.selected) ||
            this.months.some(m => m.selected);
    }

    private getMonth(month: number | string): string | number {

        const months = [
            "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"
        ];

        if (typeof month === "number")
            return months[month - 1];
        else
            return months.indexOf(month) + 1;
    }

    protected getFacetDescription(facet: FacetValue): string {

        if (facet.count === 0)
            return facet.value;

        return `${facet.value} (${facet.count})`;
    }
}
