import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { Guard } from "app/guard";
import { PageOf } from "./model/page-of";
import { PreviewableGallery } from "./model/previewable-gallery";
import { LoggerService } from '../logger.service';
import { environment } from "@env/environment";
import { IGalleryDto } from "./model/IGalleryDto";
import { GalleryDto } from "./model/GalleryDto";

@Injectable({
    providedIn: "root"
})
export class GalleryService {

    private readonly galleriesUrl: string = `${environment.apiRoot}/galleries`;

    private galleries$?: Observable<PageOf<PreviewableGallery>>;
    private galleries?: PageOf<PreviewableGallery>;
    private currentPage: number = 0;
    private currentPageSize: number = 0;

    private gallery$?: Observable<PreviewableGallery>;
    private gallery?: PreviewableGallery;
    private currentIdentifier: string = "";

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService) {
    }

    getGalleries(page: number, pageSize: number): Observable<PageOf<PreviewableGallery>> {

        this.logger.debug("gallery.service", "Get galleries");

        page = Guard.limitInteger(page, 1, 10000, { throwIfOutOfRange: false });
        pageSize = Guard.limitInteger(pageSize, 1, 20, { throwIfOutOfRange: false });

        //check if we have a cache and the current request is for the cached page
        if (this.galleries &&
            this.galleries.currentPage &&
            this.galleries.page === page) {

            this.logger.debug("gallery.service", "Cache available");

            //request is for exactly what we have in the cache so return it as is
            if (this.galleries.pageSize === pageSize) {

                this.logger.debug("gallery.service", "Exact cache hit");
                return of(this.galleries);
            }

            //request is for a subset of the cache so return the subset but keep the full cache
            if (pageSize < this.galleries.pageSize) {

                this.logger.debug("gallery.service", "Partial cache hit");
                return of(new PageOf<PreviewableGallery>(page, pageSize,
                    this.galleries.totalItems, this.galleries.currentPage.slice(0, pageSize)));
            }
        }

        //no cache hit so if we have an identical pending request then return the observable for it
        if (this.galleries$ &&
            this.currentPage === page &&
            this.currentPageSize === pageSize) {

            this.logger.debug("gallery.service", "Pending cache hit");
            return this.galleries$;
        }

        this.logger.debug("gallery.service", "No cache hit");

        //otherwise store the paging info and make the request
        //TODO: paging info could be changed by the api, make use of hal info to perform page requests
        this.currentPage = page;
        this.currentPageSize = pageSize;
        let request = `${this.galleriesUrl}?page=${page}&pageSize=${pageSize}`;
        let response$ = this.httpClient.get(request,
            {
                observe: "response",
                responseType: "json",
                headers: { "Accept": "application/hal+json" }
            });
        this.galleries$ = response$
            .pipe(
                map(response => {

                    this.logger.debug("gallery.service", "Got page of galleries");

                    const halResponse: any = response.body;
                    let galleries: PreviewableGallery[] = [];
                    let totalItems: number = 0;
                    if (halResponse?._embedded?.galleries?.length > 0) {

                        totalItems = halResponse.totalItems;
                        galleries = halResponse._embedded.galleries.map((g: IGalleryDto) => new PreviewableGallery(g));
                    }

                    return new PageOf<PreviewableGallery>(page, pageSize, totalItems, galleries);
                }),
                shareReplay());

        //once made subscribe to the observable and cache the result then destroy the observable
        this.logger.debug("gallery.service", "Subscribing");
        this.galleries$
            .subscribe({
                next: page => this.galleries = page,
                complete: () => this.galleries$ = undefined
            });

        //return the observable allowing normal subscriptions if we get here
        this.logger.debug("gallery.service", "Done");
        return this.galleries$;
    }

    getGallery(identifier: string): Observable<PreviewableGallery> {

        this.logger.debug("gallery.service", "Get gallery");

        if (Number.isInteger(identifier))
            identifier = Guard.limitInteger(Number.parseInt(identifier), 1, 100000, { throwIfOutOfRange: false }).toString();
        else
            Guard.enforcePattern(identifier, /[a-zA-Z\d -]/);

        //return the existing gallery if we have it cached
        if (this.gallery &&
            this.gallery.identifier === identifier) {

            this.logger.debug("gallery.service", "Exact cache hit");
            return of(this.gallery);
        }

        //otherwise check if we have the gallery in our list
        if (this.galleries &&
            this.galleries.currentPage) {

            this.logger.debug("gallery.service", "Checking wider cache");
            var gallery = this.galleries.currentPage.find(g => g.identifier === identifier);
            if (gallery) {

                this.logger.debug("gallery.service", "Cache hit");
                return of(gallery);
            }
        }

        //if not return any pending request for the same id
        if (this.gallery$ && this.currentIdentifier === identifier) {

            this.logger.debug("gallery.service", "Pending cache hit");
            return this.gallery$;
        }

        //otherwise store the id and make the request
        this.logger.debug("gallery.service", "Requesting gallery");
        this.currentIdentifier = identifier;
        let request = `${this.galleriesUrl}/${identifier}`;
        let response$ = this.httpClient.get<GalleryDto>(request,
            {
                observe: "response",
                responseType: "json",
                headers: { "Accept": "application/hal+json" }
            });
        this.gallery$ = response$
            .pipe(
                map(response => new PreviewableGallery(response?.body ?? new GalleryDto())),
                shareReplay());

        //once made subscribe to the observable and cache the result then destroy the observable
        this.logger.debug("gallery.service", "Subscribing");
        this.gallery$
            .subscribe({
                next: gallery => this.gallery = gallery,
                complete: () => this.gallery$
            });

        //return the observable
        this.logger.debug("gallery.service", "Done");
        return this.gallery$;
    }
}
