<div class="search-container">
    <mvp-search></mvp-search>
</div>

<mat-paginator [length]="galleryCount"
               [pageSizeOptions]="pageSizeOptions"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               (page)="paged($event)"
               showFirstLastButtons="true"
               hidePageSize="true">
</mat-paginator>

<mvp-layout [thumbnails$]="galleryPreviews$"
            [showCaptions]="true"
            (selected)="gallerySelected($event)"></mvp-layout>
