import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Observable, Subject, interval, EMPTY } from "rxjs";
import { filter, sample } from "rxjs/operators";

import { SubscribingComponent } from 'app/subscribing/subscribing.component';
import { Thumbnail } from '../model/thumbnail';
import { PreviewService } from '../preview.service';
import { LoggerService } from '../../logger.service';

@Component({
    selector: 'mvp-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends SubscribingComponent implements OnInit, OnDestroy {

    @Input()
    public title: string = "";
    @Input()
    public thumbnails$: Observable<Array<Thumbnail>> = EMPTY;
    @Input()
    public showCaptions: boolean = false;

    @Output()
    public selected = new EventEmitter<Thumbnail>();

    public rows$: BehaviorSubject<Array<Array<Thumbnail>>>;

    public loadedThumbnails: number = 0;
    public totalThumbnails: number = 0;

    private resize$: Subject<void>;
    private previousWidth: number = 0;

    constructor(
        private previewService: PreviewService,
        logger: LoggerService) {

        super(logger);

        this.rows$ = new BehaviorSubject<Array<Array<Thumbnail>>>([]);
        this.resize$ = new Subject<void>();
    }

    ngOnInit() {

        this.logger.debug("layout", "Init");

        //generate the layout once the thumbnails are available
        this.subscribe(
            this.thumbnails$
                .pipe(
                    filter(thumbnails => thumbnails !== null)),
            thumbnails => {

                this.logger.debug("layout", "Got thumbnails");

                //unload any unneeded object urls
                //TODO: will remove everything so overkill but pages could be kept for paging back and forwards
                this.previewService.unloadUnusedSources(thumbnails);
                this.generateLayout(thumbnails);
            });

        //regenerate the layout if the viewport changes but ignore multiple changes
        this.subscribe(
            this.resize$
                .asObservable()
                .pipe(
                    sample(interval(1000))),
            //flatten the rows into one array of thumbnails to regenerate the layout
            interval => this.generateLayout(this.flattenRows()));
    }

    @HostListener('window:resize')
    onResize() {

        this.logger.debug("layout", "Resize");

        //check that the width actually changed
        if (this.previousWidth !== window.innerWidth)
            this.resize$.next();
    }

    private generateLayout(thumbnails: Array<Thumbnail>) {

        this.logger.debug("layout", "[Re]generating layout");

        //store the current width of the viewport to fix a mobile browsers issue firing multiple resize events
        this.previousWidth = window.innerWidth;

        //reset these so we can count when all thumbnails have been loaded
        this.loadedThumbnails = 0;
        this.totalThumbnails = thumbnails.length;

        //hide thumbnails whilst we (re)generate the layout
        thumbnails.forEach(t => t.loaded = false);

        //allow max images per row or however many can fit allowing a minimum space for each
        //TODO: possible improvement with max = 3 and additional column(s) over certain breakpoints
        const maxColumns = 4;
        let columns = Math.min(maxColumns, Math.floor(window.innerWidth / 160));

        //possible to get a zero for innerWidth so catch this here
        if (columns === 0)
            columns = 2;

        //calc number of rows to allow for all images
        let rowCount = Math.ceil(thumbnails.length / columns);

        //for portrait add a row as long as we have enough images
        if (thumbnails.length >= maxColumns &&
            rowCount < thumbnails.length &&
            window.innerHeight > window.innerWidth)
            rowCount++;

        //calc number of thumbnails per row and check if there are extra thumbnails that need adding somewhere
        const thumbnailsPerRow = Math.floor(thumbnails.length / rowCount);
        let floatingThumbnails = thumbnails.length - thumbnailsPerRow * rowCount;

        this.logger.debug("layout", `Generating ${rowCount} rows in ${columns} columns for ${this.totalThumbnails} thumbnails`);

        let rows = new Array<Array<Thumbnail>>();
        let additionalThumbnails = 0;
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {

            //if we are on an odd row and didn't add a floating thumbnail last time we must add one this time
            //otherwise add an extra thumbnail if there are floating thumbnails - likelihood increases if more spare
            if (additionalThumbnails === 0 &&
                floatingThumbnails > 0 &&
                Math.floor((rowIndex + 1) / 2) === (rowIndex + 1) / 2)
                additionalThumbnails = 1;
            else
                additionalThumbnails = (Math.random() > (0.5 - floatingThumbnails / rowCount)) ? 1 : 0;

            //add row of standard plus additional thumbnails
            rows.push(thumbnails.splice(0, thumbnailsPerRow + additionalThumbnails));

            //reduce number of floating thumbnails if one was added
            floatingThumbnails -= additionalThumbnails;
        }

        //add any remaining thumbnails to the final row
        if (thumbnails.length > 0)
            rows[rows.length - 1] = rows[rows.length - 1].concat(thumbnails.slice(0, thumbnails.length));

        //now that we have all rows calculate thumbnail dimensions
        this.logger.debug("layout", "Calculating dimensions");
        for (let row of rows) {

            for (let thumbnail of row) {

                thumbnail.calculateDimensionsForRow(row, this.totalThumbnails);
            }
        }

        //trigger change detection
        this.rows$.next(rows);
    }

    public thumbnailLoaded(thumbnail: Thumbnail): void {

        thumbnail.loaded = true;
        this.loadedThumbnails++;
    }

    public thumbnailClicked(thumbnail: Thumbnail): void {

        this.selected.emit(thumbnail);
    }

    public formatDate(date: Date): string {

        return new Date(date).toDateString();
    }

    //ensure this is also called when the browser navigates
    @HostListener("window:beforeunload")
    override ngOnDestroy(): void {

        this.logger.debug("layout", "Destroy");

        for (const thumbnail of this.flattenRows()) {

            //cleanup all blobs in the browser
            this.previewService.unloadSources(thumbnail);
        }

        super.ngOnDestroy();
    }

    protected getCardStyles(thumbnail: Thumbnail) {

        return {
            'width.%': thumbnail.widthPercent,
            'padding-left.%': thumbnail.paddingPercent,
            'padding-right.%': thumbnail.paddingPercent,
            'margin.%': thumbnail.marginPercent
        }
    }

    private flattenRows(): Array<Thumbnail> {

        let flattened = new Array<Thumbnail>();
        for (const row of this.rows$.getValue()) {

            flattened = flattened.concat(row);
        }

        return flattened;
    }
}
