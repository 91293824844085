import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { TaggingService } from '../tagging.service';
import { LoggerService } from '../../logger.service';
import { SubscribingComponent } from 'app/subscribing/subscribing.component';
import { Thumbnail } from '../../galleries/model/thumbnail';

@Component({
    selector: 'mvp-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent extends SubscribingComponent implements OnInit {

    public tagPreviews$: BehaviorSubject<Array<Thumbnail>>;

    constructor(
        private taggingService: TaggingService,
        private router: Router,
        logger: LoggerService) {

        super(logger);

        this.tagPreviews$= new BehaviorSubject<Array<Thumbnail>>([]);
    }

    ngOnInit() {

        this.logger.debug("tag-list", "Loading");

        this.subscribe(
            this.taggingService.getTags(),
            tags => {

                this.logger.debug("tag-list", `Got ${tags.length} tags`);
                this.tagPreviews$
                    .next(tags.map(t => new Thumbnail(t.canonicalImage, t.name, t.name)));
            });
    }

    public tagSelected(event: any): void {

        const thumbnail: Thumbnail = event as Thumbnail;
        if (thumbnail === null)
            return;

        this.logger.debug("tag-list", "tagSelected");
        this.router.navigate(["tags", thumbnail.parentIdentifier]);
    }
}
