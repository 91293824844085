import { Component, Input, OnChanges, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { TaggingService } from '../tagging.service';
import { ImageDto } from '../model/ImageDto';
import { LoggerService } from '../../logger.service';
import { PicsAuthService } from 'app/pics-auth.service';
import { GalleryService } from '../gallery.service';

@Component({
    selector: 'mvp-tagging',
    templateUrl: './tagging.component.html',
    styleUrls: ['./tagging.component.scss']
})
export class TaggingComponent implements OnChanges {

    @Input()
    public image?: ImageDto;

    private availableTags: Array<string> = [];

    private readonly tKeyCode: string = "KeyT";
    private readonly equalsKeyCode: string = "Equal";
    private readonly plusKeyCode: string = "NumpadAdd";
    private readonly keyACodeValue: number = 65;
    private readonly keyZCodeValue: number = 90;
    private readonly key1CodeValue: number = 49;
    private readonly key9CodeValue: number = 57;

    protected readonly maxShortcuts: number = 18;
    protected filtered: boolean = false;

    public tags: Array<string> = [];
    public galleryTags: Array<string> = [];

    public userIsAdmin: boolean = this.authService.isAdmin;
    public isOpen: boolean = false;
    public showExisting: boolean = true;

    constructor(
        private taggingService: TaggingService,
        private galleryService: GalleryService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: PicsAuthService,
        private logger: LoggerService) {
    }

    ngOnChanges(): void {

        this.showExistingTags();
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyDown(event: KeyboardEvent) {

        this.logger.debug("tagging.component",
            `event key: ${event.key}, code: ${event.code}, keycode: ${event.keyCode}, shift/ctrl/alt: ${event.shiftKey}/${event.ctrlKey}/${event.altKey}`);
        if (this.isOpen) {

            if (this.userIsAdmin && (event.code === this.plusKeyCode || (event.shiftKey && event.code == this.equalsKeyCode)))
                this.toggleShowTags(event);

            if (this.showExisting) {

                if (event.code === this.tKeyCode)
                    this.toggleOpen(event);
            }
            else if (event.keyCode >= this.keyACodeValue && event.keyCode <= this.keyZCodeValue) {

                this.tags = this.availableTags.filter(t => t[0].toUpperCase() === event.key.toUpperCase());
                this.filtered = true;
            }
            else if (event.altKey &&
                event.keyCode >= this.key1CodeValue && event.keyCode <= this.key9CodeValue) {

                let index = event.keyCode - this.key1CodeValue;
                if (event.shiftKey)
                    index += 9;
                if (index < this.tags.length)
                    this.toggleTag(this.galleryTags[index], event);
            }
            else if (event.keyCode >= this.key1CodeValue && event.keyCode <= this.key9CodeValue) {

                let index = event.keyCode - this.key1CodeValue;
                if (event.shiftKey)
                    index += 9;
                if (index < this.tags.length)
                    this.toggleTag(this.tags[index], event);
            }
        }
        else {

            if (event.code === this.tKeyCode)
                this.toggleOpen(event);
        }
    }

    private get galleryId(): string {

        return this.route.parent?.snapshot.params["id"];
    }

    public toggleOpen(event: any): void {

        this.isOpen = !this.isOpen;
        event.stopPropagation();
    }

    protected cleanTagName(target: EventTarget | null) {

        const input = target as HTMLInputElement;
        if (input !== null)
            input.value = input.value.toLowerCase()
    }

    public showTag(tag: string, event: any) {

        this.router.navigate(["/tags", tag]);
        event.stopPropagation();
    }

    public toggleShowTags(event: any) {

        this.logger.debug("tagging.component", "show tags");
        this.filtered = false;
        if (this.showExisting) {

            this.taggingService.getTags()
                .pipe(take(1))
                .subscribe(tags => {

                    this.availableTags = tags.map(t => t.name);
                    this.tags = this.availableTags;
                    this.showExisting = false;
                });

            this.galleryService.getGallery(this.galleryId)
                .pipe(take(1))
                .subscribe(gallery => {

                    const allTags: Set<string> = new Set<string>();
                    for (const image of gallery.images) {

                        for (const tag of image.tags) {

                            if (!allTags.has(tag))
                                allTags.add(tag);
                        }
                    }

                    const tags = Array.from(allTags.keys());
                    this.galleryTags = tags.sort();
                });
        }
        else {

            this.showExistingTags();
        }

        event.stopPropagation();
    }

    public hasTag(tag: string) {

        if (this.showExisting)
            return true;

        if (!this.image || !this.image.tags)
            return false;

        //TODO: could use hashset for quicker lookups
        for (let existing of this.image.tags) {

            if (existing === tag)
                return true;
        }

        return false;
    }

    public getShortcutText(ordinal: number, prefix: string = "") {

        if (ordinal > this.maxShortcuts)
            return "";

        if (ordinal <= 9)
            return `${prefix}${ordinal}`;

        return `${prefix}s${ordinal - 9}`;
    }

    public toggleTag(tag: string, event: any) {

        if (!this.image) {

            this.logger.error(new Error("Image not set in tagging component"));
            return;
        }

        let request: Observable<boolean>;
        if (this.hasTag(tag))
            request = this.taggingService.remove(tag, this.galleryId, this.image);
        else
            request = this.taggingService.add(tag, this.galleryId, this.image);

        this.executeRequest(request, event);
    }

    public createTag(tag: string, event: any) {

        if (!this.image) {

            this.logger.error(new Error("Image not set in tagging component"));
            return;
        }

        this.executeRequest(
            this.taggingService.create(tag, this.galleryId, this.image), event);
        this.showExistingTags();
    }

    private executeRequest(request: Observable<boolean>, event: any) {

        request
            .pipe(take(1))
            .subscribe();

        event.stopPropagation();
    }

    private showExistingTags() {

        this.filtered = false;
        if (this.image && this.image.tags) {

            this.tags = this.image.tags.sort();
            this.showExisting = true;
        }
    }
}
