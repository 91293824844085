<div *ngIf="(results$ | async) as results; else loading">
    <div class="search-container">
        <mvp-search [currentSearch]="currentSearch"
                    [showControls]="true"
                    (filtersToggled)="showFilters = !showFilters"></mvp-search>
    </div>

    <mat-paginator [length]="results.totalItems"
                   [pageSize]="results.pageSize"
                   [pageIndex]="results.page - 1"
                   (page)="paged($event)"
                   showFirstLastButtons="true"
                   hidePageSize="true">
    </mat-paginator>

    <div class="filter-container" [class.closed]="!showFilters" [class.open]="showFilters">
        <div class="filters">
            <div class="button"
                 [class.active]="filtersSelected"
                 (click)="runSearch()">apply</div>
        </div>
        <div class="filters">
            <span>years</span>
            <div [class.active]="year.selected"
                 *ngFor="let year of years"
                 (click)="toggleFilter(year)">{{getFacetDescription(year)}}</div>
        </div>
        <hr />
        <div class="filters">
            <span>months</span>
            <div [class.active]="month.selected"
                 *ngFor="let month of months"
                 (click)="toggleFilter(month)">{{getFacetDescription(month)}}</div>
        </div>
        <hr />
        <div class="filters">
            <span>tags</span>
            <div [class.active]="tag.selected"
                 *ngFor="let tag of tags"
                 (click)="toggleFilter(tag)">{{getFacetDescription(tag)}}</div>
        </div>
    </div>

    <mvp-layout [thumbnails$]="resultPreviews$"
                (selected)="resultSelected($event)"></mvp-layout>
    <div class="no-results"
         *ngIf="results == null || results.totalItems === 0">
        no results!
    </div>
</div>

<ng-template #loading>
    <div class="loading">
        <img class="fixed-centre-postnav" src="../../../assets/aperture.gif" />
        <p>loading search...</p>
    </div>
</ng-template>
