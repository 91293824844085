<div class="tag-container" [class.closed]="!isOpen" [class.open]="isOpen">

    <span class="material-icons toggle"
          (click)="toggleOpen($event)">local_offer</span>
    <span class="material-icons toggle button close-button transparent"
          [class.opaque]="isOpen"
          (click)="toggleOpen($event)">close</span>
    <span *ngIf="userIsAdmin"
          class="material-icons toggle button add-button transparent"
          [class.opaque]="isOpen"
          (click)="toggleShowTags($event)">{{showExisting ? "add_box" : "backspace"}}</span>

    <div *ngIf="!showExisting" class="tag new">
        <input type="text" maxlength="15" placeholder="new tag"
               (click)="$event.stopPropagation()"
               (keyup)="cleanTagName($event.target)"
               (keyup.enter)="createTag(newtag.value, $event)"
               #newtag />
        <span class="material-icons"
              (click)="createTag(newtag.value, $event)">add</span>
    </div>

    <div *ngIf="!showExisting && userIsAdmin">
        <div *ngFor="let tag of galleryTags; let shortcut = index"
             class="tag gallery"
             [class.existing]="hasTag(tag)">
             <span (click)="showTag(tag, $event)">
                {{tag}}
                <span *ngIf="shortcut < maxShortcuts" class="shortcut">{{getShortcutText(shortcut + 1, "a")}}</span>
            </span>
            <span class="material-icons"
                  (click)="toggleTag(tag, $event)">{{hasTag(tag) ? "clear" : "add"}}</span>
        </div>
    </div>

    <div *ngFor="let tag of tags; let shortcut = index"
         class="tag"
         [class.existing]="hasTag(tag)">
        <span (click)="showTag(tag, $event)">
            {{tag}}
            <span *ngIf="!showExisting && shortcut < maxShortcuts" class="shortcut">{{getShortcutText(shortcut + 1)}}</span>
        </span>
        <span *ngIf="userIsAdmin"
              class="material-icons"
              (click)="toggleTag(tag, $event)">{{hasTag(tag) ? "clear" : "add"}}</span>
    </div>

</div>
