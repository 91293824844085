<div class="admin-container">

    <div>

        <div>
            <input type="number"
                   placeholder="grams"
                   #weight>
        </div>

        <div>
            <mat-card *ngFor="let food of defaultFoods">

                <mat-card-content (click)="logFood(food, weight.value)">
                    {{food}}
                </mat-card-content>

            </mat-card>
        </div>

    </div>

    <div>
        <input type="text"
               placeholder="new food"
               #food>

    </div>

</div>
