import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';

import { environment } from "@env/environment";

import { BehaviorSubject, Observable, Subject, of, timer } from 'rxjs';
import { map, catchError, switchMap, takeUntil, tap, finalize } from 'rxjs/operators';

import { LoggerService } from '../logger.service';
import { AvailableGallery } from './model/available-gallery';

@Injectable({
    providedIn: "root"
})
export class PublishService {

    public availableRequested$: BehaviorSubject<boolean>;
    public available$: BehaviorSubject<Array<AvailableGallery>>;

    private refreshComplete$: Subject<string>;
    private publishable: Array<PublishableGallery>;

    private readonly availableUrl: string = `${environment.apiRoot}/available`;
    private readonly galleriesUrl: string = `${environment.apiRoot}/galleries`;

    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService) {

        this.availableRequested$ = new BehaviorSubject<boolean>(false);
        this.available$ = new BehaviorSubject<Array<AvailableGallery>>([]);
        this.refreshComplete$ = new Subject();
        this.publishable = new Array<PublishableGallery>();
    }

    public reindex(galleryId: string): Observable<boolean> {

        this.logger.debug("publish.service", `re-indexing gallery id ${galleryId}`);

        return this.httpClient.request("PUT", `${this.galleriesUrl}/${galleryId}`,
            {
                observe: "response"
            })
            .pipe(
                map(response => {

                    this.logger.debug("publish.service", `re-index returned ${response.status}`);
                    return response.ok;
                }),
                catchError((err, _caught) => {

                    this.logger.debug("publish.service", `re-index of gallery id ${galleryId} failed: ${this.getErrorMessage(err) }`);
                    return of(false);
                })
            );
    }

    public publish(gallery: AvailableGallery): Observable<boolean> {

        this.logger.debug("publish.service", `publishing gallery with location '${gallery.location}'`);

        if (gallery.isPublishing || gallery.isPublishing) {

            this.logger.debug("publish.service", `gallery at location '${gallery.location}'is publishing or already published`);
            return of(false);
        }

        this.logger.debug("publish.service", `starting publish for gallery at location '${gallery.location}'`);
        gallery.isPublishing = true;

        //subscribe to this
        //in sub, poll as below using location with get by location/{location}
        return this.httpClient.request("POST", this.galleriesUrl,
            {
                body: { location: gallery.location },
                observe: "response"
            })
            .pipe(
                map(response => {

                    this.logger.debug("publish.service", `publish gallery returned ${response.status}`);
                    return response.ok;
                }),
                catchError((err, _caught) => {

                    this.logger.debug("publish.service", `publish gallery with location '${gallery.location}' failed: ${this.getErrorMessage(err) }`);
                    return of(false);
                })
            );
    }

    public refreshAvailableGalleries() {

        this.logger.debug("publish.service", "refreshing available galleries");

        if (this.availableRequested$.getValue()) {

            this.logger.debug("publish.service", "existing refresh is still running, ignoring subsequent request");
            return;
        }

        this.logger.debug("publish.service", "refreshing available galleries - starting");
        this.refreshComplete$ = new Subject();
        this.availableRequested$.next(true);

        this.requestAvailableGalleries()
            .subscribe(requestId => {

                if (requestId !== "") {

                    //take until the request succeeds or errors - could also count attempts and/or allow a cancel button
                    this.pollAvailableGalleriesStatus(requestId)
                        .pipe(takeUntil(this.refreshComplete$))
                        .subscribe({
                            next: galleries => {

                                this.logger.debug("publish.service", `refreshing available received ${galleries.length} galleries`);
                                this.publishable = galleries.map(g => new PublishableGallery(new AvailableGallery(g)))
                                this.available$.next(this.publishable.map(p => p.available));
                            },
                            complete: () => {

                                this.logger.debug("publish.service", `refreshing available galleries completed with ${this.available$.getValue().length} galleries`);
                                this.availableRequested$.next(false);
                            }
                        });
                }
            });
    }

    private requestAvailableGalleries(): Observable<string> {

        this.logger.debug("publish.service", "requesting available galleries");

        //return of("2024013113014196");

        return this.httpClient.request("POST", this.availableUrl,
            {
                observe: "response"
            })
            .pipe(
                map(response => {

                    this.logger.debug("publish.service", `request available returned ${response.status}`)
                    if (!response.headers.has("Location"))
                        throw new Error("request ID was not returned in response");

                    const requestId = (response.headers.get("Location") ?? "").replace("available", "").replace("/", "");
                    this.logger.debug("publish.service", `request ID: ${requestId}`)
                    return requestId;
                }),
                catchError((err, _caught) => {

                    this.logger.debug("publish.service", `request available failed: ${this.getErrorMessage(err) }`)
                    return of("");
                })
            );
    }

    private pollAvailableGalleriesStatus(requestId: string): Observable<Array<string>> {

        const initialDelay = 20000;
        const pollingFrequency = 2500;

        this.logger.debug("publish.service", `polling available galleries for request ID ${requestId}`);

        return timer(initialDelay, pollingFrequency)
            .pipe(
                tap(_ => this.logger.debug("publish.service", `polling attempt for request ID ${requestId}`)),

                //switch to the new request each time
                switchMap(_ => this.getAvailableGalleriesStatus(requestId))
            );
    }

    private getAvailableGalleriesStatus(requestId: string): Observable<Array<string>> {

        let message = "";
        let status = 0;

        this.logger.debug("publish.service", `getting available galleries for request ID ${requestId}`);

        return this.httpClient.request("GET", `${this.availableUrl}/${requestId}`,
            {
                observe: "response"
            })
            .pipe(
                map((response: any) => {

                    status = response.status;
                    message = `get available returned ${status}`;
                    this.logger.debug("publish.service", message);
                    if (status === HttpStatusCode.Accepted) {

                        this.logger.debug("publish.service", "get available request is not yet complete");
                        return [];
                    }

                    let galleries: Array<string> = [];
                    if (response.body && response.body.error)
                        this.logger.debug("publish.service", `get available completed with an error of ${response.body.error}`)

                    if (response.body.galleries && response.body.galleries.length > 0)
                        galleries = response.body.galleries;

                    return galleries;
                }),
                catchError((err, _caught) => {

                    this.logger.debug("publish.service", `get available failed: ${this.getErrorMessage(err)}`)
                    return [];
                }),
                finalize(() => {

                    if (status !== HttpStatusCode.Accepted)
                        this.refreshComplete$.next(message);
                })
            );
    }

    private getErrorMessage(err: any): string {

        return err.error instanceof ErrorEvent ? err.error.message : `status ${err.status}`;
    }
}

class PublishableGallery {

    public available: AvailableGallery;

    constructor(
        available: AvailableGallery,
        public publishComplete$ = new Subject()) {

        this.available = available;
    }
}
