import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { PreviewService } from "../preview.service";
import { IImageDto } from '../model/IImageDto';

//TODO: merge with layout component? These are both coupled due to object url creation and cleanup
@Component({
    selector: 'mvp-gallery-image',
    templateUrl: './gallery-image.component.html',
    styleUrls: ['./gallery-image.component.scss']
})
export class GalleryImageComponent implements OnChanges {

    @Input()
    public image: IImageDto | null = null;
    private src$ = new BehaviorSubject(this.image);

    @Input()
    public showThumbnail: boolean = true;

    @Input()
    public title: string | null = "";

    @Output()
    public loadComplete = new EventEmitter();

    //cancel the previous observable and switch to the next
    public url$ = this.src$
        .pipe(
            switchMap(image =>
                this.loadImage(image)));

    constructor(
        private previewService: PreviewService,
        private sanitizer: DomSanitizer) {
    }

    ngOnChanges(): void {

        //watch changes to the source
        if (this.image)
            this.src$.next(this.image);
    }

    private loadImage(image: IImageDto | null): Observable<SafeUrl> {

        if (image === null)
            throw new Error("Attempt to load null image!");

        return this.previewService.loadThumbnailSource(image, this.showThumbnail).pipe(
            map(url => this.sanitizer.bypassSecurityTrustUrl(url)));
    }

    public imageLoaded() {

        this.loadComplete.emit();
    }
}
