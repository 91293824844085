import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({

    declarations: [],

    imports: [
        CommonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatPaginatorModule,
        MatToolbarModule
    ],

    exports: [
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        MatPaginatorModule,
        MatToolbarModule
    ]
})
export class MaterialModule { }
