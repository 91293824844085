import { IImageDto } from "./IImageDto";

export class Thumbnail implements IImageDto {

    public readonly parentIdentifier: string | null;
    public readonly id: string | undefined;
    public readonly date: Date;
    public readonly url: string;
    public readonly isPortrait: boolean;
    public readonly tags: Array<string>;
    public readonly caption: string | null;

    public readonly marginPercent: number = 0.5;
    public readonly paddingPercent: number = 1;

    public loaded: boolean = false;
    public widthPercent: number = 0;
    //public captionFontPixels: number = 0;

    constructor(image: IImageDto, parentIdentifier: string | null = null, caption: string | null = null) {

        this.parentIdentifier = parentIdentifier;
        this.id = parentIdentifier || image.id;
        this.date = image.date;
        this.url = image.url;
        this.isPortrait = image.isPortrait;
        this.tags = image.tags;
        this.caption = caption; // || image.caption;
    }

    public calculateDimensionsForRow(row: Array<Thumbnail>, totalThumbnails: number) {

        this.calculateWidth(row, totalThumbnails);
        //this.calculateCaptionFontSize();
    }

    private calculateWidth(row: Array<Thumbnail>, totalThumbnails: number) {

        const portraitCount = row.filter(thumbnail => thumbnail.isPortrait).length;
        const landscapeCount = row.length - portraitCount;

        //images have a 3x2 ratio so for every 2 units of width a portrait's height will grow by 3
        //and a landscape's height only by 2 so we need to mulitply a landscapes factors by 1.5
        //this results in divisors of 2 for portraits and (3 x 1.5) = 4.5 for landscapes (1)
        //we also work out these relative widths after having taken off all margins and paddings (2)

        //first calc the total spacing per image (2)
        const spacing = (this.marginPercent + this.paddingPercent) * 2;

        //now calculate the factor by dividing the available percentage by the relative width factors (1)
        const factor = (100 - row.length * spacing) / (portraitCount * 2 + landscapeCount * 4.5);

        //calculate width of the thumbnails and add the padding back - this doesn't need any adjusting
        //because it's standard regardless of image orientation
        this.widthPercent = (this.isPortrait ? 2 : 4.5) * factor + (this.paddingPercent * 2);

        //enforce max width if we only have a single image
        if (totalThumbnails === 1 && this.widthPercent > 75)
            this.widthPercent = 50;
    }

    public clone(): Thumbnail {

        return new Thumbnail(this, this.parentIdentifier, this.caption);
    }

    /*private calculateCaptionFontSize() {

        //abort if we have no caption or image
        if (!this.caption ||
            this.widthPercent === 0)
            return;

        //using a baseline of 10 characters in 180px with a 30px font but allowing for two lines
        //baseline: pixels = 180 / 10 = 18px per character, so 18/30 = 0.6
        const imageWidth = ((this.widthPercent - 2 * this.marginPercent) / 100) * window.innerWidth;
        let size = (imageWidth / this.caption.length) / 0.6;

        //use a max font size of 30 so that most short captions are on one line
        this.captionFontPixels = Math.min(30, size);
    }*/
}
